<template>
  <b-row>

    <b-col
      cols="12"
      md="3"
    >
      <customer-card />
    </b-col>

    <b-col
      cols="12"
      md="9"
    >
      <validation-observer ref="simpleRules">
        <meets />
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <chasis />
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <interview-subjects />
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <InterviewStatuses :id_com_interview_type="getInterview.id_com_interview_type" />
          </b-col>

          <b-col
            cols="12"
            md=""
          >
            <users />
          </b-col>
          <b-col cols="12">
            <template v-if="getInterview.id_com_brand && getInterview.id_com_interview_subject">
              <interview-polls
                :id-com-brand="getInterview.id_com_brand"
                :id-com-interview-subject="getInterview.id_com_interview_subject"
              />
            </template>
          </b-col>
          <b-col
            cols="12"
          >
            <interview-content />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button
              variant="primary"
              class="m-sm-1 float-right"
              :disabled="!getInterview.id_com_meet || !getInterview.chasis || !getInterview.id_com_user1 || getInterview.submitStatus"
              @click="saveData"
            >
              <FeatherIcon icon="SaveIcon" />
              Kaydet
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BButton } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import Meets from '@/views/Interviews/forms/Meets.vue'
import Chasis from '@/views/Interviews/forms/Chasis.vue'
import InterviewSubjects from '@/views/Interviews/forms/InterviewSubjects.vue'
import Users from '@/views/G2Surveys/SurveyForm/Users.vue'
import CustomerCard from '@/views/G2Surveys/SurveyForm/CustomerCard.vue'
import InterviewPolls from '@/views/G2Surveys/SurveyPool/Index.vue'
import InterviewStatuses from '@/views/G2Surveys/SurveyForm/Interview_statuses.vue'
import interviewContent from '@/views/G2Surveys/SurveyForm/InterviewContent.vue'

export default {
  name: 'SurveyForm',
  components: {
    BButton,
    BRow,
    BCol,
    Meets,
    Chasis,
    InterviewSubjects,
    Users,
    CustomerCard,
    InterviewPolls,
    ValidationObserver,
    InterviewStatuses,
    interviewContent,
  },
  data() {
    return {
      dataQuery: {
        limit: 20,
        start: 0,
      },
    }
  },
  computed: {
    getInterview() {
      return this.$store.getters['interviews/getInterview']
    },
    interviewPoll() {
      return this.$store.getters['interviewPolls/getInterviewPoll']
    },
    saveStatus() {
      return this.$store.getters['interviews/getInterviewSaveStatus']
    },
    getModalStatus() {
      return this.$store.getters['g2surveys/getModalStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.getModalStatus.status = false
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
      this.getInterview.submitStatus = false
    },
  },
  created() {
    this.getUsers()
  },
  methods: {
    saveData() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // console.log(this.getInterview
          this.getInterview.submitStatus = true
          this.$store.dispatch('interviews/interviewSave', this.getInterview)
            .then(response => {
              if (response) {
                this.getDataList()
              }
            })
        }
      })
    },

    getUsers() {
      this.$store.dispatch('users/usersList')
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        allowOutsideClick: false,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    getDataList() {
      this.$store.dispatch('g2surveys/g2Sales', this.dataQuery)
    },

  },

}
</script>
