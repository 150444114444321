<template>
  <b-form-group
    label="Temas Tipi"
    label-for="id_com_meet"
  >
    <validation-provider
      #default="{ errors }"
      name="Temas Tipi"
      rules="required"
    >
      <v-select
        id="id_com_meet"
        v-model="interview.id_com_meet"
        :options="meets"
        :reduce="meet => meet.id"
        label="title"
        placeholder="Seçiniz"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Meets',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      locale: 'tr',
    }
  },
  computed: {
    interview() {
      return this.$store.getters['interviews/getInterview']
    },
    meets() {
      return this.$store.getters['meets/getMeets']
    },
  },
  created() {
    localize(this.locale)
    this.getMeets()
  },
  methods: {
    getMeets() {
      this.$store.dispatch('meets/meetsList', {
        select: [
          'com_meet.id AS id',
          'com_meet.title AS title',
        ],
        where: {
          'com_meet.hidden': null,
        },
      })
    },
  },
}
</script>

<style scoped>

</style>
