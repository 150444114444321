<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <b-card-header>
            <b-card-title>2.El Anketler</b-card-title>
          </b-card-header>
          <b-table
            responsive="sm"
            :fields="fields"
            :items="dataList"
            striped
            hover
          >
            <template #cell(customer)="data">
              <b-link
                :to="'/g2sales/view/' + data.item.id"
                target="_blank"
              >
                <div class="text-body">
                  {{ data.item.customer }}
                </div>
                <div
                  v-if="data.item.company_name"
                  class="font-small-2 text-muted"
                >
                  {{ data.item.company_name }}
                </div>
              </b-link>
            </template>
            <template #cell(company_name)="data">
              {{ data.item.company_name }}
            </template>
            <template #cell(phone)="data">
              {{ data.item.phone }}
            </template>
            <template #cell(username)="data">
              {{ data.item.username }}
            </template>

            <template #cell(license_plate)="data">
              {{ data.item.brand }} {{ data.item.model }}

              <div class="text-warning font-weight-bold font-small-2">
                {{ data.item.license_plate }}
              </div>
            </template>
            <template #cell(ddate)="data">
              {{ moment(data.item.ddate).format('DD.MM.YYYY') }}
              <div class="text-danger font-weight-bold font-small-2">
                {{ moment().diff(moment(data.item.ddate),'days') }} gün önce
              </div>
            </template>
            <template #cell(control)="data">
              <b-button
                size="sm"
                variant="outline-primary"
                @click="setModalData(data.item)"
              >
                <FeatherIcon icon="PieChartIcon" />
                Anket Oluştur
              </b-button>
            </template>

          </b-table>
          <b-card-footer>
            <b-pagination
              v-model="currentPage"
              :total-rows="dataCount"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      v-model="getModalStatus.status"
      centered
      title="İkinci El 45. Gün Anketi"
      size="xl"
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
    >
      <survey-form />
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BCardFooter, BPagination, BButton, BCardTitle, BCardHeader, BLink, BModal,
} from 'bootstrap-vue'
import moment from 'moment'
import SurveyForm from '@/views/G2Surveys/SurveyForm.vue'

export default {
  name: 'Index',
  components: {
    BCardHeader,
    BCardTitle,
    BCard,
    BTable,
    BRow,
    BCol,
    BCardFooter,
    BPagination,
    BButton,
    BLink,
    BModal,
    SurveyForm,
  },
  data() {
    return {

      currentPage: 1,
      perPage: 20,
      fields: [
        {
          key: 'customer',
          label: 'Müşteri',
          thClass: 'align-middle',
        },
        {
          key: 'phone',
          label: 'Telefon',
          thClass: 'width-200 text-nowrap align-middle',
          tdClass: 'text-nowrap',
        },
        {
          key: 'username',
          label: 'Satış Danışmanı',
          thClass: 'width-200 text-nowrap align-middle',
          tdClass: 'text-nowrap',
        },
        {
          key: 'license_plate',
          label: 'Araç Bilgisi',
          thClass: 'width-200 text-nowrap align-middle',
          tdClass: 'text-nowrap',
        },
        {
          key: 'ddate',
          label: 'Satış Tarihi',
          thClass: 'width-200 text-nowrap align-middle text-center',
          tdClass: 'text-nowrap text-center',
        },
        {
          key: 'control',
          label: '',
          thClass: 'width-100',
          tdClass: 'text-nowrap text-right',
        },
      ],
      dataQuery: {
        limit: 20,
        start: 0,
      },
      setCarcard: {
        id: null,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['g2surveys/getG2Sales']
    },
    dataCount() {
      return this.$store.getters['g2surveys/getG2SalesCount']
    },
    getCarCard() {
      return this.$store.getters['g2surveys/getCarCard']
    },
    getInterview() {
      return this.$store.getters['interviews/getInterview']
    },
    getModalStatus() {
      return this.$store.getters['g2surveys/getModalStatus']
    },

  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 20
      this.pagination(page)
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    setInterview(data) {
      this.$store.dispatch('interviews/getInterviewNumber')
        .then(response => {
          if (response) {
            this.getInterview.interview_number = response
          }
        })
      this.getInterview.initial = '1'
      this.getInterview.status = '1'
      this.getInterview.content = 'İkinci El satış memnuniyet anketi.'
      this.getInterview.id_com_interview_type = '8'
      this.getInterview.id_com_interview_subject = '17'
      this.getInterview.id_com_meet = '2'
      this.getInterview.id_com_cars = null
      this.getInterview.id_com_customer = data.id_com_customer
      this.getInterview.id_com_brand = '6'
      this.getInterview.id_com_user1 = data.id_com_user
      this.getInterview.idate = moment().format('YYYY-MM-DD')
      this.getInterview.itime = moment().format('HH:MM')
      // this.getInterview.chasis = data.order_number
      // console.log(this.getInterview)
    },

    setModalData(data) {
      this.setInterview(data)
      this.setCarcard.id_com_customer = data.id_com_customer
      this.setCarcard.chasis = data.chasis
      this.setCarcard.plate = data.license_plate
      this.setCarCards(this.setCarcard)

      this.getModalStatus.status = true
    },
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    getDataList() {
      this.$store.dispatch('g2surveys/g2Sales', this.dataQuery)
    },
    setCarCards() {
      this.$store.dispatch('g2surveys/setCarCards', this.setCarcard)
        .then(response => {
          this.getInterview.id_com_cars = response.id_com_cars
          this.getInterview.chasis = response.chasis
        })
    },
  },
}
</script>
